const middleware = {}

middleware['auth'] = require('../src/middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['booking-portal-auth'] = require('../src/middleware/booking-portal-auth.ts')
middleware['booking-portal-auth'] = middleware['booking-portal-auth'].default || middleware['booking-portal-auth']

middleware['booking-portal-sso'] = require('../src/middleware/booking-portal-sso.ts')
middleware['booking-portal-sso'] = middleware['booking-portal-sso'].default || middleware['booking-portal-sso']

middleware['maintenance-mode'] = require('../src/middleware/maintenance-mode.ts')
middleware['maintenance-mode'] = middleware['maintenance-mode'].default || middleware['maintenance-mode']

export default middleware
