import type { Context } from '@white-label-types/middleware';
import type { AuthObject } from '@white-label-types/auth';

import { isBookingPortal } from '@white-label-helper/is-booking-portal';

interface ContextWithInstalledModules extends Context {
  $auth: AuthObject;
}

const bookingPortalSSO = async ({
  redirect, query, $auth
}: ContextWithInstalledModules): Promise<unknown> => {
  if (!process.client) {
    return;
  }

  if (isBookingPortal && query.sso) {
    const {
      token, tokenExpiration, modifyBookingPath,
    } = JSON.parse(atob(query.sso as string));

    await $auth.fetchUser(token);
    $auth.setUserToken(token, tokenExpiration);

    try {
      if ($auth.isAuthenticated) {
        const redirectPath = modifyBookingPath ?? '/';
        redirect(redirectPath);
      }
    } catch (error) {
      console.log(error);
      await $auth.logout();
    }
  }
};

export default bookingPortalSSO;
